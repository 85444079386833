import * as React from "react"
import { Container } from "react-bootstrap"
import { Center } from '@mantine/core';
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"

 



 
function Destinations({data}) {

    return (
        <StaticQuery 
        query={graphql`
        query Destinations{
          allGraphCmsPageDestination {
            edges {
              node {
                pageTitle
                headerImage {
                  imageAltText
                  imagePhoto {
                    gatsbyImageData(placeholder: BLURRED, quality: 60)
                    url
                  }
                }
                pageHeaderText {
                  html
                  markdown
                  raw
                  text
                }
                itemCountries {
                  countrySlug
                  countryRegion
                  countryName
                  countryImage {
                    imageAltText
                    imagePhoto {
                      gatsbyImageData(placeholder: BLURRED, quality: 60)
                    }
                  }
                  countryDescription {
                    html
                    raw
                    markdown
                    text
                  }
                }
              }
            }
          }
          }                     
        `}
                
        render={data => (
        
    <div className="all-destinations">
                 <div className="header">
                {data.allGraphCmsPageDestination.edges.map(({ node: destination }) => (
                <div className="header-image">
                <div className="header-overlay"></div>
                  <img src={destination.headerImage.imagePhoto.url}
                                        quality={60}
                                        formats={["auto", "webp", "avif"]}
                                        alt={destination.headerImage.imageAltText}
                    />
                    <div className="header-text">
                        <h1>{destination.pageTitle}</h1>
                    </div>
                
                </div> 
                ))}

                </div>  
        


         <div className="gallery">
          
            <div className="image-gallery">

              <div className="photos">
             {data.allGraphCmsPageDestination.edges.map(({ node: countryMap }) => (
                <div  key={countryMap.id}>

                    {(countryMap.itemCountries || []).map((country, id)  => {
                    return (country.countryRegion.includes('Latin_America') || country.countryRegion.includes('Caribbean')  || country.countryRegion.includes('South_America') || country.countryRegion.includes('Middle_East') || country.countryRegion.includes('Eastern_Europe')) ?
                    <div className="main-content"  key={country.id}>
                    <div className="data-wrapper">
                    
                        <div className="estate-type">
                            <div className="image-field">
                                <GatsbyImage image={country.countryImage.imagePhoto.gatsbyImageData}
                                    quality={60}
                                    formats={["auto", "webp", "avif", "png", "jpg"]}
                                    alt={country.countryImage.imageAltText}
                                />
                                <div className="overlay">
                               
                               {country.countryName.includes("Agro") || country.countryName.includes("Georgia") || country.countryName.includes("Dubai") || country.countryName.includes("Cyprus") || country.countryName.includes("Florida, USA")
                               ?
                              
                               <div className="overlay-content">
                              
                               <a href={`/${country.countrySlug}/`}><button type="button" id="button-two" class="btn btn-primary">Buy Property</button></a>
                               </div>
                               :
                               <div className="overlay-content">
                               <a href={`/${country.countrySlug}/` + 'hotels'}><button type="button" id="button-two" class="btn btn-primary">Book a Stay</button></a>
                               <a href={`/${country.countrySlug}/` + 'realestate'}><button type="button" id="button-two" class="btn btn-primary">Buy Property</button></a>
                               </div>
                               }
                          
                            
                               
                               </div>
                            </div>
                            <div className="desc-field">
                                <div className="content">
                                    <Container> 
                                        <div className="block">
                                            <Center>
                                                <div><h2>{country.countryName}</h2></div>
                                                <div><span>{country.countryDescription.text}</span></div>
                                                
                                            </Center>
                                        </div>
                                    </Container>
                                </div>
                            </div>
                        </div> 
                 

                    </div>
                    
                </div> 
                    :null
                    })}
                </div>
                ))}
                </div>
            
            </div>

            </div>
       
	</div>
    

    )}
      />
    );
}


export default Destinations;


